import React from "react";

const VideoFrame = (props) => (
  <svg
    viewBox="0 0 345 183"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M345 0H0V183H345V0ZM71.4627 6.82772C42.4235 6.82772 18.1069 28.8524 16.4513 57.8444C13.8726 103.001 12.7537 162.841 24.2468 170C47.1768 184.284 261.233 173.742 316.909 170.735C324.366 170.333 330.094 164.17 330.094 156.702V63.2422C330.094 32.0853 304.836 6.82772 273.679 6.82772H71.4627Z"
      fill="white"
    />
  </svg>
);

export default VideoFrame;
