import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { orderBy } from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Controller, Pagination, EffectFade } from 'swiper'

import Layout from '@components/Layout'
import { Heading } from '@components/Typography'
import { Button } from '@components/Button'
import PageHeaderWrapper from '@components/PageHeaderWrapper'
import { DotMap } from '@components/decorator'
import { ScrollAnimation } from '@components/ScrollAnimation'
import { Circle } from '@components/decorator'

import VideoFrame from '@assets/img/video-frame'
import HeaderImage from '@assets/img/doctor-with-mobile.png'
import tickV from '@assets/img/tick-v.png'
import AppStore from '@assets/img/app-store.png'
import GooglePlay from '@assets/img/google-play.png'
import IconManager from '@assets/img/box-icon-managing.png'
import IconCustomization from '@assets/img/box-icon-Customization.png'
import IconCommunication from '@assets/img/box-icon-Communication.png'
import IconCommunityForum from '@assets/img/box-icon-CommunityForum.png'
import Mobile_IMI from '@assets/img/phone-with-IMI-v2.png'
import Doctor_Mobile from '@assets/img/doctor-in-laptop.png'
import Image2 from '@assets/img/doctor-with-patient.png'
import Image3 from '@assets/img/global.png'
import Play from '@assets/img/play.png'
import ImageHome2 from '@assets/img/home-1.png'
import ImageHome3 from '@assets/img/home-2.png'
import ImageHome1 from '@assets/img/home-3.png'
import ImageDoctor1 from '@assets/img/doctor-1.png'
import ImageDoctor2 from '@assets/img/doctor-2.png'
import ImageDoctor3 from '@assets/img/doctor-3.png'
import BgCircle from '@assets/img/bg-circle.png'
import ellipse from '@assets/img/ellipse.png'
// import video from '@assets/img/video.mp4'
// import Modal from "../components/Modal"

import '@assets/css/homepage.css'
import 'react-alice-carousel/lib/alice-carousel.css'

import { useTranslation } from 'react-i18next';

const TextIcon = ({ children, icon, label = '', iconClassName = '', className = '' }) => {
  return (
    <div className={['flex items-center mb-4 md:px-3 md:mb-5', className].join(' ')}>
      <img className={['mr-5', iconClassName].join(' ')} src={icon} alt="icon" />
      <div className="md:mt-[7px]">
        {label && <p className="font-bold text-xl text-black mb-2 md:text-2xl">{label}</p>}
        <p className={`text-navy-400 text-base ${label ? '' : 'md:text-lg'}`}>{children}</p>
      </div>
    </div>
  )
}

const breakpoints = {
  375: {
    slidesPerView: 2,
    spaceBetween: 125
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 1
  }
}

const IndexPage = () => {
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  const data = useStaticQuery(query)
  const { t } = useTranslation()
  const pageTrans = 'homePage:'

  const doctorsList = [
    {
      image: ImageDoctor2,
      local: 'Saigon, Vietnam',
      name: 'Dr. Ngo Tran',
      positionKey: 'doctor.generalSurgery',
      // position: 'General Surgery'
    },
    {
      image: ImageDoctor3,
      local: 'California, US',
      name: 'Dr. Edward P',
      positionKey: 'doctor.gastroenterologist',
      // position: 'Gastroenterologist'
    },
    {
      image: ImageDoctor1,
      local: 'Virginia, US',
      name: 'Dr. Rich Hayden',
      positionKey: 'doctor.generalMedicine',
      // position: 'General Medicine'
    }
  ]

  useEffect(() => {
    document.addEventListener('sal:in', scrollHandler)
    return () => document.removeEventListener('sal:in', scrollHandler)
  }, [])

  const scrollHandler = ({ detail }) => {
    if (detail.target.classList.value.includes('animate-image')) {
      setTimeout(() => {
        detail.target.querySelectorAll('.animate-image__item').forEach((elm) => {
          elm.classList.remove('hidden')
          elm.classList.add('animate__zoomIn', 'animate__slow')
        })
      }, 100)
    }
  }

  const [visibleClass, setVisible] = useState('invisible');
  const showModalVideo = () => {
    setVisible('visible');
  };
  const hideModalVideo = () => {
    setVisible('invisible');
  }

  const toppic1 = 'https://www.youtube.com/watch?v=HHurNa1tqis'
  const toppic2 = 'https://vtv.vn/video/the-gioi-goc-nhin-24-7-2021-511994.htm'
  const toppic3 = 'https://vtv.vn/the-gioi/thuoc-khang-virus-lambda-canh-tay-noi-dai-day-trien-vong-trong-cuoc-chien-chong-covid-19-20210702124418462.htm?fbclid=IwAR3SQDLuzcHDMD8Mj4XhJ6wmQC9gOH6AmkV22hUUbWsgXELCBQ_qHJ3yhTk'
  const loginForm = 'https://app.imi.ai/'
  const register = 'https://app.imi.ai/register'
  const openLinkHotTopPic = (toppic) => {
    window.open(toppic, '_blank')
  }

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <PageHeaderWrapper className="bg-[#FAFAFA]">
        <div className="pt-20 lg:pt-8 relative">
          <div className="container relative">
            <div className="grid items-center grid-cols-12 gap-[10px] lg:gap-4 lg:mt-4">
              <div className="z-20 col-span-12 lg:col-span-7 text-center lg:text-left px-0 lg:px-4 lg:pr-0 lg:pb-16 lg:mt-[24px]">
                <Heading
                  h={3}
                  className="whitespace-pre-line lg:hidden block font-extrabold relative tracking-normal text-center text-blue-700 xs:text-[28px] animate__animated animate__fadeInUp"
                >
                  {t(`${pageTrans}heading`)}
                  <DotMap className="top-[-80px] lg:top-[-90px] left-[-21px] lg:left-[-45px]" />

                </Heading>

                <Heading
                  h={2}
                  className="whitespace-pre-line lg:block hidden font-extrabold relative mb-0 text-left text-blue-700 animate__animated animate__fadeInUp"
                >
                  {t(`${pageTrans}heading`)}
                  <DotMap className="top-[-80px] left-[-21px] lg:left-[-45px]" />

                </Heading>

                <Heading h="4" className="lg:pl-2 text-blue-900 text-base lg:mb-10 animate__animated animate__fadeInUp">
                  {t(`${pageTrans}headLabel`)}
                </Heading>

                <Button onClick={() => openLinkHotTopPic(register)} size="sm"
                  className="bg-blue m-auto lg:m-0 w-[199px] h-[44px] text-xl lg:w-[264px] lg:h-[60px]"
                >
                  <span>{t(`${pageTrans}headCTA`)}</span>
                </Button>
              </div>
              <div className="relative lg:ml-[6px] w-screen lg:w-full h-[296px] lg:h-[585px] col-span-12 lg:col-span-5">
                <div
                  className="absolute mr-6 xl:w-[741px] w-full h-full right-0 top-0 z-10 bg-no-repeat bg-contain md:bg-center lg:bg-[initial]"
                  style={{ backgroundImage: `url(${HeaderImage})` }}
                />
                <DotMap className="top-[-189px] right-[-25px] lg:top-[27px] lg:right-[-28px] z-[1]" />
              </div>
            </div>
          </div>

          <div className="absolute  top-[250px] h-[100px] w-[40px]  2xl:top-[250px] 2xl:h-[350px] 2xl:w-[160px]
          xl:top-[250px] xl:h-[300px] xl:w-[120px]
          lg:top-[250px] lg:h-[220px] lg:w-[80px]
          md:top-[150px] md:h-[220px] md:w-[80px]
          ">
            <img src={ellipse} className="h-full w-auto"/>
          </div>
        </div>
      </PageHeaderWrapper>

      <section>
        <div className="container pt-2 mb-6">
          <div className="grid grid-cols-12">
            <div className="lg:col-span-6 col-span-12 order-last lg:order-none">
              <div className="relative w-full flex justify-center items-center lg:mt-0 xl:mt-32 lg:ml-[-75px] animate-image__item">
                <ScrollAnimation animation="slide-up" delay={800} duration={800} className="animate-image">
                  <div className=" relative animate-image__item-video cursor-pointer" onClick={()=>showModalVideo()}>
                    <VideoFrame className="absolute top-0 left-[0px] scale-[1.01]" />
                    <img width="100%" src={Doctor_Mobile} alt="" />
                    <img
                      className="absolute right-[-40px] bottom-[-37px] z-10 md:right-[37px] lg:right-[-40px]"
                      src={Play}
                      alt=""
                    />
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="lg:col-span-6 col-span-12 pt-10 mb-4  lg:pt-0 xl:pt-[138px]">
              <ScrollAnimation>
                <Heading
                  h="3"
                  className="lg:leading-0 text-blue text-center lg:text-left mb-6 lg:mb-8 px-2 leading-[34px] text-2xl "
                >
                  {t(`${pageTrans}section1.heading`)}
                </Heading>
              </ScrollAnimation>

              <ScrollAnimation>
                <TextIcon icon={tickV} iconClassName="w-[21px] h-[14px]">
                  {t(`${pageTrans}section1.content1`)}
                </TextIcon>
              </ScrollAnimation>

              <ScrollAnimation>
                <TextIcon icon={tickV} iconClassName="w-[21px] h-[14px]">
                  {t(`${pageTrans}section1.content2`)}
                </TextIcon>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-7">
        <div className="container">
          <div className="grid lg:grid-cols-12 grid-cols-1 content-center">
            <div className="col-span-6 pt-10 flex flex-col justify-center ">
              <ScrollAnimation>
                <Heading
                  h="3"
                  className="lg:leading-0 text-blue text-center lg:text-left mb-8 lg:pl-4 lg:pt-28 lg:pr-8 text-2xl"
                >
                  {t(`${pageTrans}section2.heading`)}
                </Heading>
              </ScrollAnimation>

              <ScrollAnimation>
                <TextIcon icon={tickV} iconClassName="w-[21px] h-[14px]">
                  {t(`${pageTrans}section2.content1`)}
                </TextIcon>
              </ScrollAnimation>

              <ScrollAnimation>
                <TextIcon icon={tickV} iconClassName="w-[21px] h-[14px]">
                  {t(`${pageTrans}section2.content2`)}
                </TextIcon>
              </ScrollAnimation>

              <ScrollAnimation>
                <TextIcon icon={tickV} iconClassName="w-[21px] h-[14px]">
                  {t(`${pageTrans}section2.content3`)}
                </TextIcon>
              </ScrollAnimation>
            </div>
            <div className="col-span-6">
              <ScrollAnimation
                animation="zoom-in"
                delay={100}
                duration={800}
                className=" lg:mt-[22px] lg:ml-[29px] mt-3 animate-image md:justify-center lg:justify-initial"
              >
                <div className="w-[343px] h-[314.93px] lg:w-auto order-2 relative animate-image__item">
                  <img src={Image2} className="w-full h-full" />
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-11">
        <div className="container pt-0 lg:pb-24">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6 h-[20rem] md:h-[34rem] lg:h-[518px] order-2 lg:order-none relative">
              <ScrollAnimation
                animation="zoom-in"
                delay={100}
                duration={800}
                className=" mt-3 animate-image ml-[-49px] mr-[-15px] lg:mt-[114px] lg:mr-[13px] xl:ml-[-206px] lg:ml-[-77px]"
              >
                <div className="animate-image__item  Image3 z-10 w-full h-auto mt-[-22px] ">
                  <img src={Image3} className="w-full h-full ml-[-4px]" />
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-span-12 lg:col-span-6 lg:pt-[48px]">
              <ScrollAnimation>
                <Heading
                  h="3"
                  className="text-blue text-center lg:text-left mb-7 leading-[34px] px-2 text-2xl lg:hidden block"
                >
                  {t(`${pageTrans}section3.heading`)}
                </Heading>
                <Heading
                  h="3"
                  className="lg:leading-0 text-blue text-center lg:text-left mb-8 lg:pl-4 lg:pt-28 lg:pr-8 text-2xl hidden lg:block"
                >
                  {t(`${pageTrans}section3.heading`)}
                </Heading>
              </ScrollAnimation>

              <div className="grid grid-cols-1 md:grid-cols-2 xl:mt-20 md:pt-2">
                <ScrollAnimation>
                  <TextIcon
                    className="col-span-1 md:flex-col md:items-start"
                    label={t(`${pageTrans}section3.label1`)}
                    icon={IconManager}
                    iconClassName="w-[48px] mr-[17px] md:w-[60px] md:h-[70px]"
                  >
                    {t(`${pageTrans}section3.content1`)}
                  </TextIcon>
                </ScrollAnimation>

                <ScrollAnimation>
                  <TextIcon
                    className="col-span-1 md:flex-col md:items-start"
                    label={t(`${pageTrans}section3.label2`)}
                    icon={IconCustomization}
                    iconClassName="w-[48px] mr-[17px] md:w-[60px] md:h-[70px]"
                  >
                    {t(`${pageTrans}section3.content2`)}
                  </TextIcon>
                </ScrollAnimation>

                <ScrollAnimation className=" md:mt-[10px]">
                  <TextIcon
                    className="col-span-1 md:flex-col md:items-start"
                    label={t(`${pageTrans}section3.label3`)}
                    icon={IconCommunication}
                    iconClassName="w-[48px] mr-[17px] md:w-[60px] md:h-[70px]"
                  >
                    {t(`${pageTrans}section3.content3`)}
                  </TextIcon>
                </ScrollAnimation>

                <ScrollAnimation className=" md:mt-[10px]">
                  <TextIcon
                    className="col-span-1 md:flex-col md:items-start"
                    label={t(`${pageTrans}section3.label4`)}
                    icon={IconCommunityForum}
                    iconClassName="w-[48px] mr-[17px] md:w-[60px] md:h-[70px]"
                  >
                    {t(`${pageTrans}section3.content4`)}
                  </TextIcon>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* NOTE: hide app downloads in MVP version */}
      <section id="homepage-s5" className="mt-[2px] lg:mt-[12px]">
        <div className="container lg:pt-8">
          <div className="grid grid-cols-12 pt-10 mb-10 lg:mb-8 relative content-download">
            <div className="col-span-12 lg:col-span-7 pt-2 text-center lg:pt-1">
              <ScrollAnimation >
                <Heading
                  h="3"
                  className="text-white text-center mb-8 leading-[34px] text-2xl lg:leading-normal lg:mb-[4px]"
                >
                  {t(`${pageTrans}section4.heading`)}
                </Heading>
              </ScrollAnimation>

              <ScrollAnimation className=" justify-center">
                <p className="text-blue-100 text-base lg:text-2xl leading-normal font-bold mb-[18px]">
                  {t(`${pageTrans}section4.content1`)}
                </p>
              </ScrollAnimation>

              <div>
                <ScrollAnimation animation="zoom-in" delay={100} duration={800} className="animate-image justify-center">
                  <div className="animate-image__item">
                    <div className="flex justify-center space-x-4 lg:space-x-6 h-[44px] lg:h-[48px]">
                      <a href="https://apps.apple.com/us/app/imi-health/id1490228563" target="_blank" className="block">
                        <img
                          className="w-[147px] lg:w-[180px]"
                          src={AppStore}
                          alt="app store"
                        />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=ai.imi.imihealth" target="_blank" className="block">  
                        <img
                          className="w-[147px] lg:w-[180px]"
                          src={GooglePlay}
                          alt="google play store"
                        />
                      </a>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-5 relative h-[392px] lg:h-[320px]">
              <ScrollAnimation animation="zoom-in" delay={100} duration={800} className=" mt-3 animate-image">
                <div className="animate-image__item w-full h-full">
                  <img
                    className="absolute xl:w-[456px] xl:h-[530px] w-[343px] top-[39px] lg:top-[-148px] left-0 lg:left-[-10px] md:left-1/2 md:-translate-x-1/2 lg:-translate-x-0"
                    src={Mobile_IMI}
                    alt=""
                  />
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container grid grid-cols-12 gap-x-7 gap-y-[2px] partner-list lg:pt-20">
          <div className="lg:col-span-1 hidden lg:block"></div>
          {orderBy(data.strapiHomepage.partner, 'name').map((p) => (
            <div className="col-span-6 lg:col-span-2 text-center partner-list-item" key={p.id}>
              <ScrollAnimation
                animation="zoom-in"
                delay={10}
                duration={400}
                className=" animate-image md:justify-center lg:justify-initial"
              >
                <GatsbyImage image={getImage(p.localFile)} alt={`partner ${p.name}`} />
              </ScrollAnimation>
            </div>
          ))}
        </div>
      </section>

      <section>
        <div className="container">
          <div className="mt-12 lg:mt-20 lg:pt-6">
            <div>
              <ScrollAnimation className=" justify-center">
                <h2 className="lg:text-4xl text-2xl font-extrabold text-blue-700 text-center">
                  {t(`${pageTrans}section5.heading`)}
                </h2>
              </ScrollAnimation>
              <ScrollAnimation className=" justify-center">
                <p className="lg:text-xl text-base mt-6 text-gray-300 text-center">
                  {t(`${pageTrans}section5.content1`)}
                </p>
              </ScrollAnimation>
            </div>

            <div className="mr-[-15px] ml-[-15px] lg:mt-[65px] lg:pl-[14px] lg:pr-[14px]">
              <div className="pb-7 doctor-list mt-[37px]">
                <div className="w-full flex justify-center overflow-visible">
                  <div className="w-full">
                    <Swiper
                      loop
                      roundLengths
                      centeredSlides
                      breakpoints={breakpoints}
                      loopAdditionalSlides={30}
                      modules={[Controller, EffectFade]}
                      onSwiper={setFirstSwiper}
                      effect="creative"
                      controller={{ control: secondSwiper }}
                    >
                      {doctorsList.map((doctor, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="flex justify-center mx-auto mt-[10px]">
                              <div className="relative mb-[34px]">
                                <div className="absolute bottom-[-22px]">
                                  <span className="text-xl text-navy-300 opacity-10 font-bold">0{index + 1}</span>
                                </div>
                                <div className="item-image w-[168px] h-[168px] rounded-[50%]">
                                  <img src={doctor.image} alt="" className="w-full" />
                                </div>
                                <div className="absolute top-[-7px] left-[-6px] z-[-1] w-[176px] h-[176px]">
                                  <img src={BgCircle} alt="" className="rounded-[50%]" />
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>

              <div className="pt-0 doctor-list doctor-list-content mt-[-35px]">
                <Swiper
                  loop
                  roundLengths
                  centeredSlides
                  loopAdditionalSlides={30}
                  breakpoints={breakpoints}
                  pagination={{ clickable: true }}
                  modules={[Controller, Pagination, EffectFade]}
                  effect="creative"
                  onSwiper={setSecondSwiper}
                  controller={{ control: firstSwiper }}
                >
                  {doctorsList.map((doctor, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="flex justify-center mx-auto pb-[90px] lg:pb-0">
                          <div className="relative">
                            <div className="item-image text-center" style={{ minWidth: "200px" }}>
                              <p className="text-2xl font-bold text-blue-700">{doctor.name}</p>
                              <p className="text-xl font-extrabold mt-[16px]">
                                <span className="text-base text-gray-300">{t(`${pageTrans}${doctor.positionKey}`)}</span>
                              </p>
                              <p className="mt-[10px]">
                                <span className="text-gray-300">{doctor.local}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lg:mb-24 mb-8 mt-[-67px]">
        <div className="container">
          <div className="mt-[111px] lg:mt-40 lg:pt-2">
            <div className="pt-1 relative">
              <DotMap
                className="top-[10px] right-[-79px] lg:top-[5px] lg:right-[-33px] z-[1] lg:hidden block"
                style={{ height: 114, width: 140, zIndex: 1 }}
              />
              <DotMap
                className="top-[55px] right-[-92px] z-[1] lg:block hidden"
                style={{ height: 102, width: 102, zIndex: 1 }}
                color="#779FC2"
              />
              <div className="absolute h-[109.32px] w-[109.32px] lg:h-[150px] lg:w-[150px] top-[-48px] right-[-87px]  lg:top-[-27px]  lg:right-[-167px]">
                <Circle className="animate__delay-2s h-[109.32px] w-[109.32px] lg:h-[150px] lg:w-[150px] bg-orange-400" />
              </div>
              <ScrollAnimation className=" justify-center">
                <h2 className="lg:text-4xl text-2xl font-bold text-blue-700 text-center">{t(`${pageTrans}section6.heading`)}</h2>
              </ScrollAnimation>

              <div className="grid grid-cols-12 lg:px-4 lg:gap-5">
                <div className="lg:col-span-4 col-span-12">
                  <div className=" mt-8 lg:mt-10 cursor-pointer" onClick={() => openLinkHotTopPic(toppic1)}>
                    <ScrollAnimation
                      animation="zoom-in"
                      delay={10}
                      duration={800}
                      className=" justify-center animate-image"
                    >
                      <div className="animate-image__item animate__animated  top-0 left-0 animate__delay-1s z-10 w-[343px] h-[234px] md:w-full lg:h-[249px] lg:w-full rounded-tr-[12px] rounded-tl-[12px] overflow-hidden">
                        <img src={ImageHome1} className="w-full h-full object-cover" />
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="container lg:px-4">
                    <div className="mt-4 cursor-pointer" onClick={() => openLinkHotTopPic(toppic1)}>
                      <ScrollAnimation>
                        <h2 className="lg:text-2xl text-xl font-bold lg:mt-[11px]">
                          {t(`${pageTrans}section6.label1`)}
                        </h2>
                      </ScrollAnimation>
                    </div>
                    <div className="mt-3 cursor-pointer" onClick={() => openLinkHotTopPic(toppic1)}>
                      <ScrollAnimation>
                        <p className="text-base text-gray-300">
                          {t(`${pageTrans}section6.content1`)}
                        </p>
                      </ScrollAnimation>
                    </div>

                    <div className="mt-6">
                      <ScrollAnimation className="justify-start">
                        <Button
                          size="sm"
                          className="border-[1px] border-blue-700 text-blue-700 text-xl"
                          style={{ backgroundColor: '#fff', padding: '8px 32px' }}
                          onClick={() => openLinkHotTopPic(toppic1)}
                        >
                          {t(`${pageTrans}section6.cta1`)}
                        </Button>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12">
                  <div>
                    <div className=" mt-[34px] lg:mt-10 cursor-pointer" onClick={() => openLinkHotTopPic(toppic2)}>
                      <ScrollAnimation
                        animation="zoom-in"
                        delay={10}
                        duration={800}
                        className=" justify-center animate-image"
                      >
                        <div className="animate-image__item animate__animated  top-0 left-0 animate__delay-1s z-10 w-[343px] h-[234px] md:w-full lg:h-[249px] lg:w-full rounded-tr-[12px] rounded-tl-[12px] overflow-hidden">
                          <img
                            src={data.strapiHomepage.seo.shareImage.localFile.publicURL}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </ScrollAnimation>
                    </div>

                    <div className="container lg:px-4">
                      <div className="mt-4 cursor-pointer" onClick={() => openLinkHotTopPic(toppic2)}>
                        <ScrollAnimation>
                          <h2 className="lg:text-2xl text-xl font-bold lg:mt-[11px]">
                            {t(`${pageTrans}section6.label2`)}
                          </h2>
                        </ScrollAnimation>
                      </div>
                      <div className="mt-3 cursor-pointer" onClick={() => openLinkHotTopPic(toppic2)}>
                        <ScrollAnimation>
                          <p className="text-base text-gray-300">
                            {t(`${pageTrans}section6.content2`)}
                          </p>
                        </ScrollAnimation>
                      </div>

                      <div className="mt-6">
                        <ScrollAnimation className="justify-start lg:pt-[7px] lg:pl-[5px] ">
                          <Button
                            size="sm"
                            className=" border-[1px] border-blue-700 text-blue-700 text-xl"
                            style={{ backgroundColor: '#fff', padding: '8px 32px' }}
                            onClick={() => openLinkHotTopPic(toppic2)}
                          >
                            <a onClick={() => openLinkHotTopPic(toppic2)}>{t(`${pageTrans}section6.cta1`)}</a>
                          </Button>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12">
                  <div>
                    <div className=" mt-[34px] lg:mt-10 cursor-pointer" onClick={() => openLinkHotTopPic(toppic3)}>
                      <ScrollAnimation
                        animation="zoom-in"
                        delay={10}
                        duration={800}
                        className=" justify-center animate-image"
                      >
                        <div className="animate-image__item animate__animated  top-0 left-0 animate__delay-1s z-10 w-[343px] h-[234px] md:w-full lg:h-[249px] lg:w-full rounded-tr-[12px] rounded-tl-[12px] overflow-hidden">
                          <img src={ImageHome3} className="w-full h-full object-cover" />
                        </div>
                      </ScrollAnimation>
                    </div>
                    <div className="container lg:px-4">
                      <div className="mt-4 cursor-pointer" onClick={() => openLinkHotTopPic(toppic3)}>
                        <ScrollAnimation>
                          <h2 className="lg:text-2xl text-xl font-bold lg:mt-[11px]">
                            {t(`${pageTrans}section6.label3`)}
                          </h2>
                        </ScrollAnimation>
                      </div>
                      <div className="mt-3 cursor-pointer" onClick={() => openLinkHotTopPic(toppic3)}>
                        <ScrollAnimation>
                          <p className="text-base text-gray-300">
                            {t(`${pageTrans}section6.content3`)}
                          </p>
                        </ScrollAnimation>
                      </div>

                      <div className="mt-6">
                        <ScrollAnimation className="justify-start lg:pt-[7px] lg:pl-[5px] ">
                          <Button
                            size="sm"
                            className=" border-[1px] border-blue-700 text-blue-700 text-xl"
                            style={{ backgroundColor: '#fff', padding: '8px 32px' }}
                            onClick={() => openLinkHotTopPic(toppic3)}
                          >
                            {t(`${pageTrans}section6.cta1`)}
                          </Button>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="mt-12 lg:mt-16 lg:pt-1">*/}
            {/*  <ScrollAnimation className=" justify-start lg:justify-center">*/}
            {/*    <Button size="lg" className="bg-blue w-full text-xl lg:w-[200px] lg:h-[48px] lg:p-0">*/}
            {/*      View More*/}
            {/*    </Button>*/}
            {/*  </ScrollAnimation>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={`fixed top-[-100px] left-0 right-0 bottom-0 modal-video ${visibleClass}`} >
          <div className="fixed top-[10%] left-[10%] right-[10%] bottom-[10%]  modal-layout " >
            <span className="absolute btn-close-modal" onClick={()=>hideModalVideo()}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg></span>
            {/*<video controls='true' className="video-modal">*/}
            {/*  <source src={video} type="video/mp4" />*/}
            {/*</video>*/}
          </div>

        </div>
      </section>
    </Layout>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      headerTitle
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      partner {
        id
        name
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allStrapiArticle(limit: 3) {
      nodes {
        slug
        title
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default IndexPage
